import { GET_CLOCK, SET_CLOCK } from "../actionTypes/clockActionTypes";

export const getClock = () => {
    return {
      type:  GET_CLOCK,
    };
  };
  
  export const setClock = (action) => {
    return {
      type:  SET_CLOCK,
      payload : action
    };
  };
  