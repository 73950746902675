export const  GET_DATA = "GET_DATA"
export const  SET_DATA = "SET_DATA"

export const SET_FILTERED_DATA = "SET_FILTERED_DATA"


 
 


