import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MealCreate from '../pages/MealCreate';
import RecipeList from '../pages/RecipeList';
 


const AppRoutes = () => {
  return (
   
    <Router>
 
    <Routes>
    <Route path="/" element={<RecipeList />} />
    <Route path="/meal-create/:id" element={<MealCreate/>} />
  
      {/* Add more routes here */}
    </Routes>
  </Router>  )
}

export default AppRoutes




