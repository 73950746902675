import { GET_DATA, SET_DATA, SET_FILTERED_DATA } from "../actionTypes/allDataActionTypes";


export const fetchAllData = () => {
  return {
    type:  GET_DATA,
  };
};

export const setAllData = (action) => {
  return {
    type:  SET_DATA,
    payload : action
  };
};

export const setFilteredData = (filteredData) => {
  return {
    type: SET_FILTERED_DATA,
    filteredData
  };
};
