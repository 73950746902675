import React, { useState } from "react";
import Navigation from "../components/Navigation";
import { SearchIcon } from "../components/Icons";
import RecipeCard from "../components/RecipeCard";
import { useSelector } from "react-redux";

const RecipeList = () => {
  const [searchValue, setSearchValue] = useState("")
  const dataSheet = useSelector((state) => state.data.data);
  const recipeListData = dataSheet?.recipeList;

  function searchByRecipeName(recipeName) {
    const searchResults = recipeListData?.filter(item => item.recipeName.toLowerCase().includes(recipeName.toLowerCase()));
    return searchResults;
  }
  const searchResults = searchByRecipeName(searchValue);



  return (

    <div className="flex gap-4">
      {recipeListData?.length > 0 ?
        <>
          <div className="side-bar">
            <Navigation />
          </div>
          <div className="md:w-11/12 w-full md:ms-auto">
            <div className="md:px-[80px] py-5">
              <div className="sticky-top w-full p-3">
                <div class="relative md:w-4/12">
                  <span class="pointer-events-none absolute inset-y-0  grid w-10 place-content-center text-gray-50">
                    <SearchIcon />
                  </span>
                  <input
                    type="search"
                    placeholder="Search Recipes"
                    class="w-full rounded-md p-2 border-gray-100 pl-10 shadow-sm sm:text-sm bg-[#e7e7f6] text-[#424040]"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>

              <div className="md:px-[3px] px-[18px] py-5">
                <div className="gap-4 pt-5 grid grid-cols-12">
                  {searchValue.length >= 3 ?
                    searchResults?.map((items) => (
                      <div className="lg:col-span-6 md:col-span-6 col-span-12 flex">
                        <RecipeCard recipeListData={items} />
                      </div>
                    )) :
                    recipeListData?.map((items) => (
                      <div className="lg:col-span-6 md:col-span-6 col-span-12 flex">
                        <RecipeCard recipeListData={items} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
         :
         <div class="h-screen w-full bg-white">
           <div class="flex justify-center items-center h-full">
             <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
           </div>
         </div>
       }
    </div>

  );
};

export default RecipeList;
