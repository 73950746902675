import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useSelector } from "react-redux";
import { Left, Right, } from "./Icons";
import { PauseCircle, PlayCircle } from "react-feather";
import KeepAwake from "./KeepAwake";

const Tables = ({ active, isPlayingPrep, setIsPlayingPrep, isPlayingMethod, setIsPlayingMethod }) => {
  const filteredData = useSelector((state) => state.filterData.filteredData);
  const [prepData, setPrepData] = useState([]);
  const [methodData, setMethodData] = useState([]);
  const [hasComplete, setHasComplete] = useState(false)
  const [isPlayingPrepTable, setIsPlayingPrepTable] = useState(false);
  const [isPlayingMethodTable, setIsPlayingMethodTable] = useState(false);
  const [hasCompleteMethod, setHasCompleteMethod] = useState(false)
  const [prepRemaining, setPrepRemaining] = useState(false)
  const [methodRemaining, setMethodRemaining] = useState(false)
  const [overlapTime, setOverlapTime] = useState(0);
const [totalTimeWithoutOverlap, setTotalTimeWithoutOverlap] = useState(0);
const [newTime, setNewTime] = useState(0);




  const handleTogglePlay = () => {
    setIsPlayingPrep((prevState) => !prevState);
  };

  const handleMethodTogglePlay = () => {
    setIsPlayingMethod((prevState) => !prevState);
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    
    if (hasComplete && timerValue) {
      setIsPlayingPrep(false);
      // return <div className="timer">00:00</div>;
    }
    if (remainingTime > 1) {
      setIsPlayingPrepTable(true);
    }
    if (remainingTime > 1) {
      setIsPlayingMethodTable(true);
    }
    setPrepRemaining(remainingTime)

    return (
      <div className="timer">
        <div className="value">{hasComplete === true ? "00:00" : formatTime(totalPrepTime)}</div>
      </div>
    );
  };

  const renderMethodTime = ({ remainingTime }) => {
    if (hasCompleteMethod && timerMethodValue) {
      setIsPlayingMethod(false);
      // return <div className="timer ">kk:00</div>;
    }
    if(totalMethodTime===0){
      setIsPlayingMethod(false);
    }

    if (remainingTime > 1) {
      setIsPlayingMethodTable(true);
    }

    setMethodRemaining(remainingTime)
    return (
      <div className="timer">
        <div className="value" >{hasCompleteMethod === true ? "00:00" : formatTime(totalMethodTime)}</div>
      </div>
    );
  };


  //  convert sec into min 00:00
  function convertToTime(value) {
    const data = [value];
    var result = "";

    for (var i = 0; i < data.length; i++) {
      var minutes = Math.floor(data[i] / 60);
      var seconds = data[i] % 60;

      if (minutes < 10) {
        result += "0";
      }

      result += minutes + ":";

      if (seconds < 10) {
        result += "0";
      }

      result += seconds;

      if (i < data.length - 1) {
        result += ", ";
      }
    }

    return result;
  }

  // method logic timer
  const [totalMethodTime, setTotalMethodTime] = useState(0)
  const [initialMethodTotalTime, setInitialMethodTotalTime] = useState(0)
  const [timerMethodValue, setTimerMethodValue] = useState(0)

  // useEffect(() => {
  //   let totalMethodTime = 0
  //   for (let i = 0; i < filteredData.length; i++) {
  //     const item = filteredData[i];
  //     totalMethodTime += item.methodTime
  //   }
  //   setTotalMethodTime(totalMethodTime)
  //   setInitialMethodTotalTime(totalMethodTime)
  //   setTimerMethodValue(totalMethodTime)
  // }, [filteredData])

  useEffect(() => {
    let totalMethodTime = 0;
    let overlapTime = 0;
    let previousEndTime = 0;
  
    for (let i = 0; i < filteredData?.length; i++) {
      const item = filteredData[i];
      totalMethodTime += item.methodTime;
  
      // Check for overlap
      if (item.startMethodTime < previousEndTime) {
        overlapTime += previousEndTime - item.startMethodTime;
      }
  
      previousEndTime = item.endMethodTime;
    }
  
    const totalTimeWithoutOverlap = totalMethodTime - overlapTime;
    const newTime = totalMethodTime - overlapTime;
  
    // Use the values as needed
    setOverlapTime(overlapTime);
    setTotalTimeWithoutOverlap(totalTimeWithoutOverlap);
    setNewTime(newTime);
    setTotalMethodTime(newTime);
    setInitialMethodTotalTime(newTime);
    setTimerMethodValue(newTime);
  }, [filteredData]);
  
  

  useEffect(() => {
    let recipeMethodTimer = initialMethodTotalTime - totalMethodTime;
    let recipeMethodData = []
    for (let i = 0; i < filteredData?.length; i++) {
      const item = filteredData[i];

      if ((recipeMethodTimer > item.startMethodTime && recipeMethodTimer < item.endMethodTime) || (recipeMethodTimer === item.startMethodTime)) {
        item.methodRunningStatus
          = true;
      } else if ((recipeMethodTimer > item.endMethodTime) || recipeMethodTimer === item.endMethodTime) {
        item.methodCompleteStatus = true;
      } else {
        item.methodRunningStatus
          = false;
        item.methodCompleteStatus = false;
      }
      recipeMethodData.push(item)

      if (hasCompleteMethod) {
        item.methodCompleteStatus = true;
      }
    }

    const groupedData = recipeMethodData?.reduce((acc, obj) => {
      const key = obj.foreignKeyElement;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key]?.push(obj);
      return acc;
    }, {});

    const methodFilterData = Object.keys(groupedData)?.map((key) => ({
      foreignKeyElement: key,
      data: groupedData[key],
    }));

    setMethodData(methodFilterData);
  }, [filteredData, hasCompleteMethod, initialMethodTotalTime, totalMethodTime])
  const skipMethodSteps = (value) => {
    setTotalMethodTime((totalMethodTime) => value === "remove" ? (totalMethodTime - 30) : (totalMethodTime + 30))
    if (value === "remove") {
      setTimerMethodValue(totalMethodTime - 30)
    } else {
      setTimerMethodValue(totalMethodTime + 30)
    }
  }

  useEffect(() => {
    if (totalMethodTime > 0 && isPlayingMethod) {
      const update = () => {
        setTotalMethodTime((totalMethodTime) => totalMethodTime - 1)
      }
      const interval = setInterval(update, 1000);
      return () => clearInterval(interval);
    }
  }, [isPlayingMethod, totalMethodTime]);

  // ---- prep timer logic start --------------
  const [totalPrepTime, setTotalPrepTime] = useState(0)
  const [initialTotalTime, setInitialTotalTime] = useState(0)
  const [timerValue, setTimerValue] = useState(0)

  useEffect(() => {
    let totalPrepTime = 0
    for (let i = 0; i < filteredData?.length; i++) {
      const item = filteredData[i];
      if (item.prepId !== "") {
        totalPrepTime += item?.prepTime
      }
    }
    setTotalPrepTime(totalPrepTime)
    setInitialTotalTime(totalPrepTime)
    setTimerValue(totalPrepTime)
   
  }, [filteredData])

  useEffect(() => {
    let recipePrepsTimer = initialTotalTime - totalPrepTime;
    let recipeData = []
    for (let i = 0; i < filteredData?.length; i++) {
      const item = filteredData[i];
      if (item.prepId !== "") {
        if ((recipePrepsTimer > item.startPrepTime && recipePrepsTimer < item.endPrepTime) || (recipePrepsTimer === item.startPrepTime)) {
          item.prepRunningStatus = true;
        } else if ((recipePrepsTimer > item.endPrepTime) || recipePrepsTimer === item.endPrepTime) {
          item.prepCompleteStatus = true;
        } 
        else {
          item.prepRunningStatus = false;
          item.prepCompleteStatus = false;
        }
        recipeData.push(item)
      }
      if (hasComplete) {
        item.prepCompleteStatus = true;
      }
      
    }
     setPrepData(recipeData); 
  }, [totalPrepTime, initialTotalTime, filteredData, hasComplete])

  const skipSteps = (value) => {
    setTotalPrepTime((totalPrepTime) => value === "remove" ? (totalPrepTime - 30) : (totalPrepTime + 30))
    if (value === "remove") {
      setTimerValue(totalPrepTime - 30)
    } else {
      setTimerValue(totalPrepTime + 30)
    }
  }
  useEffect(() => {
    if (totalPrepTime > 0 && isPlayingPrep) {
      const update = () => {
        setTotalPrepTime((totalPrepTime) => totalPrepTime - 1)
      }
      const interval = setInterval(update, 1000);
      return () => clearInterval(interval);
    }
  }, [isPlayingPrep, totalPrepTime]);

  return (
    <>
      <>
      {/* <KeepAwake /> */}
        <div className={`flex-custom sm:pb-0 pb-6 ${active !== "Prep" ? "hidden" : ""}`}>
          <div className={`flex sm:justify-end  sm:pb-6 sm:-mt-[87px] pe-3 ${active !== "Prep" ? "hidden" : ""} `}>
            <div className="timer-wrapper" >
              <CountdownCircleTimer
                isPlaying={isPlayingPrep}
                duration={timerValue}
                colors={false}
                // colorsTime={[10, 6, 3, 0]}
                // onComplete={() => {
                //   setHasComplete(true);
                //   // Call the custom function
                //   // return { shouldRepeat: false }; // Repeat the timer after 1 second
                // }}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>
          <div className={`flex title-sm-14px text-[#7B61FF] items-center py-4 lg:pe-1 pe-3  justify-end sm:-mt-[27px] ${active !== "Prep" ? "hidden" : ""}`}>
            <button  disabled={hasComplete}className={`pe-3${hasComplete}?"cursor-no-drop":cursor-pointer  `} >
              {(prepRemaining+1>initialTotalTime) ?
              <Left  className="cursor-no-drop" /> 
               :  
              <Left onClick={() => skipSteps("add")} />
               }
            </button>
            <button className="md:px-4 px-12 cursor-pointer">
              {!isPlayingPrep ?
                <PlayCircle onClick={handleTogglePlay} />
                :
                <PauseCircle onClick={handleTogglePlay} />
              }
            </button>
            <button disabled={hasComplete} className={`pe-3${hasComplete && prepRemaining===0 }?"cursor-no-drop":cursor-pointer`} >
             {prepRemaining<=40         ?
             <Right className="cursor-no-drop" /> 
             : 
             <Right  onClick={() => skipSteps("remove")}/>
              } 
            </button>

          </div>
        </div>
        <div className={`flex-custom sm:pb-0 pb-6  ${active !== "Method" ? "hidden" : ""} `}>
        <div className={`flex sm:justify-end  sm:pb-6 sm:-mt-[87px] pe-3 `}>
          <div className="timer-wrapper" >
            <CountdownCircleTimer
              isPlaying={isPlayingMethod}
              duration={timerMethodValue}
              colors={false}
              // colorsTime={[10, 6, 3, 0]}
              // onComplete={() => {
              //   setHasCompleteMethod(true) // Call the custom function
              //   // return { shouldRepeat: false }; // Repeat the timer after 1 second
              // }}
            >
              {renderMethodTime}
            </CountdownCircleTimer>
          </div>
        </div>
        <div className={`flex title-sm-14px text-[#7B61FF] items-center py-4  lg:pe-1 pe-3  justify-end sm:-mt-[27px] ${active !== "Method" ? "hidden" : ""}`}>
          <button disabled={hasCompleteMethod} className={`pe-3${hasCompleteMethod}?"cursor-no-drop":cursor-pointer`} >
            {(methodRemaining+1> initialMethodTotalTime) ?<Left className="cursor-no-drop" />  :<Left onClick={() => skipMethodSteps("add")} /> }
          </button>
          <button className="md:px-4 px-12 cursor-pointer" >
            {!isPlayingMethod ?
              <PlayCircle onClick={handleMethodTogglePlay} />
              :
              <PauseCircle onClick={handleMethodTogglePlay} />
            }
          </button>
          <button disabled={hasCompleteMethod} className={`pe-3${hasCompleteMethod && methodRemaining===0}?"cursor-no-drop":cursor-pointer`} >
            {(methodRemaining<=60)?<Right className="cursor-no-drop" />: <Right  onClick={() => skipMethodSteps("remove")}/>}
          </button>

        </div>
        </div>
       
      </>

      <div class=" overflow-scroll sm:overflow-hidden  border-[#B9B9B9]">
        {active === "Ingredients" ? (
          <>
            <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm table sm:mb-14 mb-14">
              <thead class="ltr:text-left rtl:text-right">
                <tr>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900 w-10">
                    No
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Ingredient
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200">
                {filteredData?.map(
                  (items, index) =>
                    items.ingredientsId !== "" && (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {items.ingIdSeqNo}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {items.ingredientsId}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </>
        ) : null}
        {active === "Prep"  ? (
          <>
            <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm table sm:mb-10 mb-8">
              <thead class="ltr:text-left rtl:text-right">
                <tr>
                  <th class=" px-4 py-2 font-medium text-gray-900 w-10">No</th>
                  <th class=" px-4 py-2 font-medium text-gray-900">
                    Preparation
                  </th>
                  <th class=" px-4 py-2 font-medium text-gray-900">
                    Time(MM:SS)
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200">
                {prepData?.map((items, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        className={`whitespace-nowrap px-4 py-2 text-gray-700
                         ${isPlayingPrepTable === true && items.prepCompleteStatus === true ? "bg-[#7B61FF80]" : ""}
                         ${isPlayingPrepTable === true && items.prepRunningStatus === true ? "active_table" : ""}`}
                      >
                        {index + 1}
                      </td>
                      <td
                        className={`whitespace-nowrap px-4 py-2 text-gray-700 ${isPlayingPrepTable === true && items.prepCompleteStatus === true ? "bg-[#7B61FF80]" : ""
                          } ${isPlayingPrepTable === true && items.prepRunningStatus === true ? "active_table" : ""}`}
                      >
                        {items.prepId}
                      </td>
                      <td
                        className={`whitespace-nowrap px-4 py-2 text-gray-700 ${isPlayingPrepTable === true && items.prepCompleteStatus === true ? "bg-[#7B61FF80]" : ""
                          } ${isPlayingPrepTable === true && items.prepRunningStatus
                            === true ? "active_table" : ""}`}
                      >
                        {convertToTime(items.prepTime)}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </>
        ) : null}

        {(active === "Method") ? (
          <>
            <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm table overflow-x-auto sm:mb-14 mb-14 ">
              <thead class="ltr:text-left rtl:text-right">
                <tr>
                  <th class=" px-4 py-2 font-medium text-gray-900 w-10">No</th>
                  <th class=" px-4 py-2 font-medium text-gray-900">Method</th>
                  {/* <th class=" px-4 py-2 font-medium text-gray-900">
                    Dependency
                  </th> */}
                  <th class=" px-4 py-2 font-medium text-gray-900">
                    Time(MM:SS)
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {methodData.map((items, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {index + 1}
                      </td>
                      <td
                        colSpan=""
                        className="whitespace-nowrap px-4 py-2 font-bold text-color"
                      >
                        {items.foreignKeyElement}
                      </td>
                    
                      <td
                        colSpan=""
                        className="whitespace-nowrap px-4 py-2 font-medium text-color"
                      >
                      </td>
                    </tr>
                    {items.data.map((values, innerIndex) => (
                      <tr key={innerIndex}>
                        <td
                          className={`whitespace-nowrap px-4 py-2 text-gray-700
                         ${isPlayingMethodTable === true && values.methodCompleteStatus === true ? "bg-[#7B61FF80]"
                              : ""
                            }
                         ${isPlayingMethodTable === true && values.methodRunningStatus
                              === true ? "active_table" : ""
                            }`}
                        ></td>
                        <td
                          className={`whitespace-nowrap px-4 py-2 text-gray-700
                         ${isPlayingMethodTable === true && values.methodCompleteStatus === true ? "bg-[#7B61FF80]" : ""}
                         ${isPlayingMethodTable === true && values.methodRunningStatus
                              === true ? "active_table" : ""}`}
                        >
                          {values.methodId}
                        </td>
                        {/* <td
                          className={`whitespace-nowrap px-4 py-2 text-gray-700
                         ${isPlayingMethodTable === true && values.methodCompleteStatus === true
                              ? "bg-[#7B61FF80]": ""}
                         ${isPlayingMethodTable === true && values.methodRunningStatus
                              === true ? "active_table" : ""}`}>
                          {values.dependency}
                        </td> */}
                        <td
                          className={`whitespace-nowrap px-4 py-2 text-gray-700
                         ${isPlayingMethodTable === true && values.methodCompleteStatus === true
                              ? "bg-[#7B61FF80]": ""}
                         ${isPlayingMethodTable === true && values.methodRunningStatus
                              === true ? "active_table" : ""}`}>
                          {convertToTime(values.methodTime)}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </>
        ) : null
        }
      </div>
    </>
  );
};

export default Tables;
