import {  SET_DATA, SET_FILTERED_DATA } from "../actionTypes/allDataActionTypes";

const initialState = {
  filteredData: []
  // Other state properties
};

export const allDataListReducer = (state= [], action) => {
  switch (action.type) {
    case  SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};


export const allFilterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.filteredData
      };
    // Other cases
    default:
      return state;
  }
};
