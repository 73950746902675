import { call, put, takeLatest } from "redux-saga/effects";
import { GET_DATA } from "../../actionTypes/allDataActionTypes";
import { allDataListApi } from "../requests/allDataRequests";
import { setAllData } from "../../actions/allDataAction";
 
function* getAllDataList(){
    try{
        const data= yield call(allDataListApi);
        yield put(setAllData(data?.data));
    }catch(error){
        console.error('Error ', error);

    }
}

export default function* allDataSaga(){
    yield takeLatest(GET_DATA,getAllDataList)
}