import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import Tables from "../components/Tables";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClock } from "../redux/actions/clockAction";
import { setFilteredData } from "../redux/actions/allDataAction";

const MealCreate = () => {
  let params = useParams();
  const [active, setActive] = useState("Ingredients");
  const [isPlayingPrep, setIsPlayingPrep] = useState(false);
  const [isPlayingMethod, setIsPlayingMethod] = useState(false);

  const dispatch = useDispatch();

  const recipeWithMethod = useSelector(
    (state) => state?.data?.data?.filteredMethodByElementId
  );
  const recipeData = useSelector((state) => state.data?.data?.recipeList);

  const filteredData = recipeWithMethod?.filter(
    (item) => item.recipeId?.toString() === params.id
  );

  useEffect(() => {
    const filteredData = recipeWithMethod?.filter(
      (item) => item.recipeId?.toString() === params.id
    );
    dispatch(setFilteredData(filteredData));
  }, [dispatch, params.id, recipeWithMethod]);
  const filterRecipeDataById = recipeData?.filter(
    (item) => item.recipeId?.toString() === params.id
  );
  console.log("filterRecipeDataById", filterRecipeDataById === undefined);
  useEffect(() => {
    let newData;
    let i = 1;
    newData = filteredData?.filter((data) => data.ingredientsId !== "");
    newData?.forEach((object) => {
      object.ingIdSeqNo = i++;
    });
  }, [filteredData, active]);

  useEffect(() => {
    let newData;
    let i = 1;
    newData = filteredData?.filter((data) => data.prepId !== "");
    newData?.forEach((object) => {
      object.prepIdSeqNo = i++;
    });
  }, [filteredData, active]);

  useEffect(() => {
    if (active === "Prep") {
      dispatch(getClock());
    }
  }, [dispatch, active]);

  function convertDuration(duration) {
    const seconds = parseInt(duration);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours?.toString().padStart(2, "0");
    const formattedMinutes = minutes?.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds?.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <>
      {filterRecipeDataById === undefined ? (
        <div class="h-screen w-full bg-white">
          <div class="flex justify-center items-center h-full">
            <img
              class="h-16 w-16"
              src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="flex gap-4">
          <div className="side-bar">
            <Navigation />
          </div>
          <div className="md:w-11/12 w-full md:ms-auto">
            <div className="md:px-[80px] px-[18px] py-5">
              <div className="flex justify-between md:items-top items-center">
                <div className="title-main text-color font-medium">
                  {filterRecipeDataById?.[0]?.recipeName}
                </div>
                <div></div>
              </div>
              <div></div>

              <div className="mb-4">
                <div className="flex  items-center ">
                  <div className="title  text-color mr-10 w-76">
                    {filterRecipeDataById?.[0]?.recipeDescription}
                  </div>
                  {/* <div className="flex title-sm-14px text-color items-center ">
              Save
              <Star className="ml-1" color="#808080" size={20} />
            </div>
            <div className="flex  font-medium text-base text-color items-center ">
              <Upload className="ml-3" color="#808080" size={20} />
            </div> */}
                </div>
                <div className="flex  mt-4 font-medium text-base text-color">
                  <div className="mr-4 title-sm-14px font-medium">
                    Serves:- {filterRecipeDataById?.[0]?.numberOfServings}
                  </div>
                  <div className="ml-10 title-sm-14px font-medium">
                    Estimated Time :{" "}
                    {convertDuration(
                      filterRecipeDataById?.[0]?.calculatedTotalTimeInSec
                    )}{" "}
                    min
                  </div>
                </div>
                {/* <audio controls>
            <source src={notificationAudio} type="audio/ogg" />
          </audio> */}
                <div className="flex justify-between pb-8">
                  <div className="mt-3">
                    <div className="md:inline-flex flex -space-x-px overflow-hidden rounded-md border-0 bg-[#c6beec] shadow-sm">
                      <button
                        onClick={() => setActive("Ingredients")}
                        className={`inline-block px-4 py-2 text-sm font-medium  md:w-32 w-28 focus:relative btn-success ${
                          active === "Ingredients" ? "active" : ""
                        }`}
                      >
                        Ingredients
                      </button>

                      <button
                        onClick={() => {
                          setActive("Prep");
                          setIsPlayingMethod(false);
                        }}
                        className={`inline-block px-4 py-2 text-sm font-medium  md:w-32 w-28  focus:relative btn-success ${
                          active === "Prep" ? "active" : ""
                        } `}
                      >
                        Prep
                      </button>

                      <button
                        onClick={() => {
                          setActive("Method");
                          setIsPlayingPrep(false);
                        }}
                        className={`inline-block px-4 py-2 text-sm font-medium  md:w-32 w-28  focus:relative btn-success  ${
                          active === "Method" ? "active" : ""
                        }`}
                      >
                        Method
                      </button>
                    </div>
                  </div>
                </div>
                <Tables
                  active={active}
                  isPlayingPrep={isPlayingPrep}
                  setIsPlayingPrep={setIsPlayingPrep}
                  isPlayingMethod={isPlayingMethod}
                  setIsPlayingMethod={setIsPlayingMethod}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MealCreate;
