import React from "react";
import RecipesImg from "../assets/images/Recipes.png";
import { MyRecipesIcon } from "./Icons";
import { Link } from "react-router-dom";
const RecipeCard = ({recipeListData}) => {
  return (
  
    <article class="border-b-[1px] border-[#CFCFCF]  bg-white relative w-full">
      <div class="flex items-start gap-4 p-4 sm:p-3 lg:p-3 mb-5 ">
        <>
          <img
            alt="Speaker"
            src={recipeListData.imagUrl}
            class="h-[80px] min-w-[90px] max-w-[90px] rounded-sm object-cover"
          />
        </>

        <div>
          <h3 class="font-medium mb-0">
            <Link to={`/meal-create/${recipeListData.recipeId}`} class="title-sm-16px text-ellipsis font-medium text-color">
            {recipeListData.recipeName}
            </Link>
          </h3>
          <h4 class="font-medium sm:text-lg">
            <p class="title-sm-14px text-truncate2">
           
            {recipeListData.recipeDescription}
            </p>
          </h4>

          {/* <p class="line-clamp-2  text-gray-700 title-sm-14px">X Time Ago</p> */}
        </div>
        {/* <div className="recipe-star-icon">
  <MyRecipesIcon/>
  </div> */}
      </div>
    </article>
  );
};

export default RecipeCard;
