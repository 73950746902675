import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from "./redux/store"
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  <Provider store={store.reduxStore}>
    <PersistGate loading={null} persistor={store.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

