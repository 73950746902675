// import React, { useEffect } from 'react';

// const KeepAwake = () => {
//   useEffect(() => {
//     let animationFrameId;

//     const keepScreenAwake = () => {
//       animationFrameId = requestAnimationFrame(keepScreenAwake);
//     };

//     keepScreenAwake();

//     return () => {
//       cancelAnimationFrame(animationFrameId);
//     };
//   }, []);

//   return null;
// };

// export default KeepAwake;


import React, { useEffect, useRef, useState } from 'react';

function KeepAwake() {
  const [buttonActive,setButtonActive]=useState(false)
  const buttonRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (buttonRef.current) {
        buttonRef.current.click();
      }

    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = () => {
    setButtonActive(true)
    setInterval(()=>{
      setButtonActive(false)
    },3000)
  };

  return (
    <button ref={buttonRef} onClick={handleClick}>
      {buttonActive?
      "Auto Click Button"
      
      :""}
    </button>
  );
}

export default KeepAwake;


