import AppRoutes from './routes/AppRoutes';
import "./index.css"
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchAllData } from './redux/actions/allDataAction';

function App() {
  const dispatch =useDispatch()
  useEffect(()=>{
    dispatch(fetchAllData())
  },[dispatch])
  return (
    <div className="App">
      <AppRoutes/>
    </div>  
  );
}

export default App;
