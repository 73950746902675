import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import {
  HomeIcon,
  MyRecipesIcon,
  UserIcon,
} from "../components/Icons";
import { fetchAllData } from "../redux/actions/allDataAction";
import { useDispatch } from "react-redux";


const Navigation = () => {
  
  const dispatch = useDispatch();
  const Navigate=useNavigate()
  const changeRoutes=()=>{
    Navigate("/")
    window.location.reload();
    dispatch(fetchAllData());
  }
  
  return (
    <div className="">
      <ul className="w-full h-full pb-8">
        <li className="">
          <Link
            to="/"
            className="text-blue-500 hover:text-blue-800 md:block hidden"
          >
            <img src={logo} alt="logo" />
          </Link>
        </li>
        <li className="hover:text-blue-800 cursor-pointer">
          <div 
            onClick={(changeRoutes)}
      
          >
            <span className="icon">
              {" "}
              <HomeIcon />
            </span>
            Home
          </div>
        </li>
        {/* <li className="">
          <Link to="/meal-create" className="text-[#7B61FF] hover:text-[#7B61FF]">
            <span className="icon">
              {" "}
              <Mealcreate />
            </span>
            Meal create
          </Link>
        </li> */}
        {/* <li className="">
          <Link to="/" className="text-blue-500 hover:text-blue-800">
            <span className="icon">
              {" "}
              <SearchIcon />
            </span>
            Search
          </Link>
        </li> */}
        <li className="">
          <span  className=" hover:text-blue-800">
            <span className="icon">
              {" "}
              <MyRecipesIcon />
            </span>
            My Recipes
          </span>
        </li>
        <li className="">
          <span className=" hover:text-blue-800">
            <span className="icon">
              {" "}
              <UserIcon />
            </span>
            You / Me
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
