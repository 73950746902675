import axios from "axios"
const baseURL = "https://script.google.com/macros/s/AKfycbwn_b4klaMncaZRCwzlC1UNPXEb8dpOzVZwWQlGxMlHZGwWVyQwzUFcSufezpKP5UVV/exec"






export const allDataListApi = async () => {
    return await axios.get(baseURL)
  }



